@charset "utf-8";
@import url('https://fonts.googleapis.com/earlyaccess/nanummyeongjo.css');
@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

/* 공통 */
body {
    font-family: 'Noto Sans KR', 'Pretendard', sans-serif !important;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.v-input-blod input {
    font-weight: bold !important;
}
.empty-nodata {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.v-text {
    font-size:13.6px;
}
.v-horizontal {
    display: flex;
}

.concierge-date-picker input {
    color: #e91e63 !important;
    font-weight: 400 !important;
}
.concierge-date-picker-empty input {
    color: #ffffff !important;
    font-weight: 400 !important;
}

/* 다이얼로그 */
.react-confirm-alert-overlay {
    z-index: 9999 !important;
    background: rgba(0, 0, 0, 0.4) !important;
}
.react-confirm-alert-body {
    width: 480px !important;
    font-family: 'Noto Sans KR', 'Pretendard', sans-serif !important;
    font-size: 15.4px !important;
    text-align: center;

}
.react-confirm-alert-body > h1 {
    font-size: 18px !important;
    margin-bottom: 10px !important;
}
.react-confirm-alert-button-group {
    justify-content: center !important;
}
.react-confirm-alert-button-group > button {
    font-size: 14px;
    font-weight: 600;
    width: 72px;
    height: 36px;
    background-color: #1A73E8 !important;
    margin-left: 5px;
    margin-right: 5px;
}

/* 테이블 */
.thover {
    max-width: 100%;
}

.thover a {
    color: #344767;
}
.thover thead tr {
    background-color: #ffffff;
    border-bottom-color: #D9D9D9 !important;
    box-shadow: 0 5px 5px -5px #AAA;
}
.thover tbody tr:hover {
    background-color: #F9F9F9;
    cursor: pointer;
}
.thover tbody td {
    text-align: left !important;
    border-bottom-width: 0.5px !important;
    border-bottom-color: #BBB !important;
}
.thover tbody td span {
    font-size: 0.86rem;
}
.thover th {
    min-width: 100px !important;
}
.pnation button {
    font-size: 14px !important;
}
.full-height {
    height: calc(100vh - 150px) !important;
}
.store-table tbody td span {
    font-size: 0.8rem !important;
}


/* 대시보드 상태바 */
.dashboard-bar {
    width: 100%;
    display: flex;
    align-items: center;
}
.dashboard-bar-item {
    height: 36px;
    display: flex;
    align-items: center;
}
.dashboard-bar-split {
    margin-right:  16px;
    text-align: center;
}
.dashboard-caption {
    text-align: center;
    margin-left: 6px;
    line-height: 22px;
    margin-right: 16px;
    text-align: left;
}
.dashboard-refresh-view {
    padding-left: 16px;
    padding-bottom: 2px;
}
.dashboard-refresh-btn {
    width: 100px;
}
.dashboard-date-view {
    width: 100%;
    display: flex;
    justify-content : flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
}
.dashboard-date-dash {
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    color:#222;
}
.dashboard-date-item {
    width:50%;
    max-width: 150px;
    min-width: 150px;
}
.dashboard-date-item input {
    font-weight: 900;
}
.dashboard-bar-hr {
    display: none;
}

/* 공백 페이지 */
.empty-view {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty-card {
    text-align: center;
    width: 600px;
    height: 400px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 로딩패널 */
.spinner-view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.flag-box {
    width: 80px;
    line-height: 38px;
    height: 38px;
    background-color: #F6F6F6 !important;
    text-align: center;
    border-radius: 6px;
}


/* 모바일 */
@media only screen and (max-width: 768px) {
    .dashboard-bar {
        width: 100%;
        min-width: 100%;
        display: inline;;
        padding-top: 10px;
        padding-bottom : 10px;
        padding-left : 2px;
    }
    .dashboard-bar-split {
        display: none;
    }
    .dashboard-date-view {
        margin-top: 10px;
    }
    .dashboard-bar-hr {
        display: block;
    }
    .dashboard-bar-hr hr {
        border-top: 1px dashed #CCC;
    }
    .dashboard-date-view {
        justify-content : center;
    }
    .dashboard-refresh-view {
        padding-left: 0px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .dashboard-refresh-btn {
        width: 304px;
    }
    .dashboard-date-item {
        width:50%;
        max-width: 140px;
        min-width: 140px;
    }

}